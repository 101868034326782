//@author: devin
import { S25Datefilter } from "../s25-dateformat/s25.datefilter.service";
import { UserprefService } from "../../services/userpref.service";
import { jSith } from "../../util/jquery-replacement";
import { S25Const } from "../../util/s25-const";
import { S25Util } from "../../util/s25-util";
import { FormatService } from "../../services/format.service";
import { PricingService } from "../../services/pricing.service";
import { ContactService } from "../../services/contact.service";
import { TaskService } from "../../services/task/task.service";
import { BpeBillItem, EmailScenario, EmailTemplate, ScenarioAttributeItem, ScenarioItem } from "./bpe.service";
import { ScenarioItemTypeName } from "../system-settings/event-save-email/s25-email-scenario/s25.email.scenario.component";
import { BpeScenarioTypes, BpeVarsI } from "./BpeI";
import { Rules } from "../s25-rule-tree/s25.rule.const";
import { Proto } from "../../pojo/Proto";
import NumericalBoolean = Proto.NumericalBoolean;
import { lineItemType, OccurrenceSubtotal } from "../../pojo/Pricing";

function strVar(value: string) {
    //ANG-3850 removed skip of ' added for ANG-3290, when we send emails we run unescapeXml on the subject
    return S25Util.escapeXml(S25Util.toStr(value), false);
}

function generateDateStrings(targetObj: any, timeFormat: string, dateFormat: string, dateTimeFormat: string) {
    let dates = S25Util.deepCopy(targetObj.dates);
    jSith.forEach(dates, function (key, val) {
        if (val) {
            key = key.replace("Date", "");
            targetObj.dates[key + "DateTimeString"] = S25Datefilter.transform(val, dateTimeFormat);
            targetObj.dates[key + "DateString"] = S25Datefilter.transform(val, dateFormat);
            targetObj.dates[key + "TimeString"] = S25Datefilter.transform(val, timeFormat);
        }
    });
}

function buildTable(
    headerArray: any[],
    dataRowArray: any[],
    rowF: any,
    preProcessRowF?: any,
    source?: "document" | "scenario" | "template",
) {
    let table: string[] = [
        '<table style="border-collapse:collapse;border: 1px solid #d4d4d4;box-shadow: 2px 3px 7px -8px #000000bf;border-radius:3px;font-family: helvetica,sans-serif;margin-top:1em;"><thead><tr style="border-bottom: 1px solid #d4d4d4;">',
    ];

    const style = {
        header: {
            color: "#737487",
            "font-weight": "400",
            padding: "5px",
            "font-size": "1rem",
            "text-align": "center",
            "border-right": "1px solid #d4d4d4",
        },
        cell: {
            padding: "10px",
            "vertical-align": "middle",
            "font-size": "1rem",
            "white-space": "normal",
            "text-align": "left",
            "border-right": "1px solid #d4d4d4",
        },
    };

    if (source === "document") {
        style.header.padding = "0.1em";
        style.cell.padding = "0.1em";
        style.cell["vertical-align"] = "top";
    }

    const headerStyle = Object.entries(style.header)
        .map(([key, val]) => `${key}: ${val};`)
        .join(" ");
    const headers = headerArray.map((header) => `<th style="${headerStyle}">${header}</th>`);

    table.push(headers.join("") + "</tr></thead><tbody>");

    const cellStyle = Object.entries(style.cell)
        .map(([key, val]) => `${key}: ${val};`)
        .join(" ");

    let rows: string[] = [];
    jSith.forEach(dataRowArray, function (key, row) {
        let cells: string[] = [];
        let processedRow = preProcessRowF && preProcessRowF(row);
        jSith.forEach(headerArray, function (i) {
            let cellData = rowF(row, i, processedRow);
            if (S25Util.isDefined(cellData)) {
                cells.push(`<td style="${cellStyle}">${cellData}</td>`);
            }
        });

        if (cells.length) {
            rows.push('<tr style="border-bottom: 1px solid #d4d4d4;">' + cells.join("") + "</tr>");
        }
    });

    table.push(rows.join("") + "</tbody></table>");
    return headers.length && rows.length ? table.join("") : "";
}

//Keep headerArray for easy swap between buildTable and buildCSV
function buildCSV(headerArray: any[], dataRowArray: any[], rowF: any) {
    let rowsStr = "";
    jSith.forEach(dataRowArray, function (key, row) {
        let cellData = rowF(row, 0);
        if (S25Util.isDefined(cellData)) {
            if (rowsStr) {
                rowsStr += ", ";
            }
            rowsStr += cellData;
        }
    });
    return rowsStr || "";
}

function occRowF(rowItem: any, cellNum: any) {
    return [rowItem.dates.rsrvStartDateTimeString, rowItem.dates.rsrvEndDateTimeString, strVar(rowItem.rsrvStateName)][
        cellNum
    ];
}

function occLocRowF(rowItem: any, cellNum: any) {
    if (rowItem.objectType === 4) {
        return [
            rowItem.occ.dates.rsrvStartDateTimeString,
            rowItem.occ.dates.rsrvEndDateTimeString,
            strVar(rowItem.occ.rsrvStateName),
            strVar(S25Util.propertyGetVal(rowItem.object, "space_name")),
        ][cellNum];
    }
}

function occResRowF(rowItem: any, cellNum: any) {
    if (rowItem.objectType === 6) {
        return [
            rowItem.occ.dates.rsrvStartDateTimeString,
            rowItem.occ.dates.rsrvEndDateTimeString,
            strVar(rowItem.occ.rsrvStateName),
            strVar(S25Util.propertyGetVal(rowItem.object, "resource_name")),
        ][cellNum];
    }
}

function occLocResRowF(rowItem: any, cellNum: any) {
    return [
        rowItem.occ.dates.rsrvStartDateTimeString,
        rowItem.occ.dates.rsrvEndDateTimeString,
        strVar(rowItem.occ.rsrvStateName),
        rowItem.objectType === 4 ? "Location" : "Resource",
        strVar(
            S25Util.propertyGetVal(rowItem.object, "space_name") ||
                S25Util.propertyGetVal(rowItem.object, "resource_name"),
        ),
    ][cellNum];
}

function locRowF(rowItem: any, cellNum: any) {
    return [rowItem.itemName, rowItem.itemFormalName][cellNum];
}

function resRowF(rowItem: any, cellNum: any) {
    return [rowItem.itemName][cellNum];
}

let rsrvStateToName: any = {
    1: "Active",
    2: "Exception",
    3: "Warning",
    4: "Override",
    99: "Cancelled",
};

function variableKeyValues(variables: any, currentKey?: string, keyValues?: any) {
    currentKey = currentKey || "";
    keyValues = keyValues || {};
    jSith.forEach(variables, function (key, obj) {
        key = (currentKey === "" ? "" : currentKey + ".") + key;
        if (typeof obj === "object" && !S25Util.date.isDate(obj) && !S25Util.array.isArray(obj)) {
            variableKeyValues(obj, key, keyValues);
        } else {
            keyValues[key] = obj;
        }
    });
    return keyValues;
}

function getSelectedItemIdsAsString(selectedItems: any[]) {
    return (
        "[" +
        selectedItems
            .map(function (item) {
                return item.itemId;
            })
            .join(", ") +
        "]"
    );
}

function getSelectedItemsAsString(selectedItems: any[]) {
    return (
        "[" +
        selectedItems
            .map(function (item) {
                return S25Util.stringify({ itemId: item.itemId });
            })
            .join(", ") +
        "]"
    );
}

function getSelectedItemsTest(selectedItems: any[], varName: string) {
    return (
        (!selectedItems || selectedItems.length === 0
            ? "true"
            : getSelectedItemIdsAsString(selectedItems) + ".indexOf(" + varName + ") > -1") + ";\n"
    );
}

function getSelectedItemsByIdTest(selectedItems: any[], varName: string) {
    if (!selectedItems || selectedItems.length === 0) {
        return "true;\n";
    } else {
        return (
            varName +
            " && " +
            (getSelectedItemIdsAsString(selectedItems) + ".indexOf(" + varName + ".itemId) > -1") +
            ";\n"
        );
    }
}

function customAttributeToCode(itemId: number, operator: string, itemTypeId: string, itemValue: string, index: number) {
    let test = `let proAttributeValue${index} = $pro.util.getCustomAttributeValue(${itemId.toString()}, $pro.eventData);\n`;
    test += `let attributesTest${index} = proAttributeValue${index}`;
    let type =
        ["S", "X", "R"].indexOf(itemTypeId) > -1
            ? "string"
            : ["D"].indexOf(itemTypeId) > -1
              ? "date"
              : ["E"].indexOf(itemTypeId) > -1
                ? "dateTime"
                : ["T"].indexOf(itemTypeId) > -1
                  ? "time"
                  : ["N", "F"].indexOf(itemTypeId) > -1
                    ? "numeric"
                    : ["B"].indexOf(itemTypeId) > -1
                      ? "bool"
                      : parseInt(itemTypeId) > -1
                        ? "object"
                        : "";
    if (type === "object") {
        //resource, org, contact, location
        //operator: =
        let stringValue = itemValue.toString();
        test += ` === ${stringValue};\n`;
    } else if (type === "date") {
        //operator: =, >= etc
        operator = operator === "=" ? "===" : operator;
        test += ";\n";
        test += `date1 = $pro.util.toDateString(proAttributeValue${index});\n`;
        test += `date2 = $pro.util.toDateString("${itemValue}");\n`;
        test += `attributesTest${index} = date1 ${operator} date2;\n`;
    } else if (type === "time") {
        //operator: =, >= etc
        operator = operator === "=" ? "===" : operator;
        test += ";\n";
        test += `date1 = $pro.util.toTimeString(proAttributeValue${index});\n`;
        test += `date2 = $pro.util.toTimeString("${itemValue}");\n`;
        test += `attributesTest${index} = date1 ${operator} date2;\n`;
    } else if (type === "dateTime") {
        //operator: =, >= etc
        operator = operator === "=" ? "===" : operator;
        test += ";\n";
        test += `date1 = $pro.util.toDateTimeString(proAttributeValue${index});\n`;
        test += `date2 = $pro.util.toDateTimeString("${itemValue}");\n`;
        test += `attributesTest${index} = date1 ${operator} date2;\n`;
    } else if (type === "numeric") {
        //operator: =, >= etc
        if (operator === "=") {
            test += ` === ${itemValue.toString()};\n`;
        } else {
            test += ` ${operator} ${itemValue.toString()};\n`;
        }
    } else if (type === "string") {
        itemValue = itemValue.toString().toLowerCase();
        if (operator === "=") {
            test += `.toLowerCase() === "${itemValue}";\n`;
        } else if (operator === "contains") {
            test += `.toLowerCase().includes("${itemValue}");\n`;
        }
    } else if (type === "bool") {
        //operator: =
        //reset test text because its different for bools
        test = `let proAttributeValue${index} = $pro.util.getCustomAttributeValue(${itemId.toString()}, $pro.eventData);\n`;
        //if an attribute isn't on the event proAttributeValue = ''
        test += `let attributesTest${index} = proAttributeValue${index} !== '' && $pro.util.toBool(proAttributeValue${index}) === $pro.util.toBool('${itemValue}');\n`;
    } else {
        test += `\n`;
    }
    return test;
}

function getSelectedAttributesTest(attributes: ScenarioAttributeItem[], varName: string) {
    if (!attributes?.length) return "let $attributesTest = true;\n";

    let test = "";
    for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];
        test += customAttributeToCode(
            attribute.itemId,
            attribute.operator,
            attribute.custAtrbType as string,
            attribute.itemValue,
            i,
        );
    }
    test += `let $attributesTest = ${attributes.map((_, i) => `attributesTest${i}`).join(" || ")}\n`;
    return test;
}

function getSelectedItemsIntersectionTest(selectedItems: any[], varName: string) {
    return (
        (!selectedItems || selectedItems.length === 0
            ? "true"
            : "$pro.util.shallowIntersection(" +
              getSelectedItemsAsString(selectedItems) +
              ", " +
              varName +
              ", ['itemId']).length > 0") + ";\n"
    );
}

/*
Get the todo variable values for the specifided todo. If no todoId provided, use newest todo (largest todoId on the event)
*/
function getTodoVars(todos: any, todoId: number) {
    todos = S25Util.array.forceArray(todos);
    let todo: any;
    if (todoId) {
        todo = S25Util.array.getByProp(todos, "todo_id", todoId);
    } else {
        todos = todos.filter((todo: any) => {
            return todo.todo_subtype === 99;
        });
        todos.sort(S25Util.shallowSort("todo_id", true));
        todo = todos[0];
    }

    return S25Util.all({
        assignTo: ContactService.getContactEmail(todo.cur_assigned_to_id),
        assignBy: ContactService.getContactEmail(todo.cur_assigned_by_id),
    }).then((resp) => {
        return {
            assignToEmail: resp.assignTo,
            assignByEmail: resp.assignBy,
            dueDate: todo.due_dt,
            comment: todo.todo_description,
            status: TaskService.taskStateToStateText(todo.cur_todo_state, 2),
            subtype: todo.todo_subtype == 99 ? "Event Cancel Request" : "Event Todo",
            history: todo.history,
        };
    });
}

export class BpeUtil {
    public static templateToCode(template: EmailTemplate) {
        let code =
            "class Email { constructor(to, from, cc, bcc, reports, iCalFile, subject, body) { this.to = to; this.from = from; this.cc = cc; this.bcc = bcc; this.reports = reports; this.iCalFile = iCalFile;  this.subject = subject; this.body = body; } }\n\n";
        code += "let $to = '" + S25Util.nestedStr(template.to) + "';\n";
        code += "let $from = '" + S25Util.nestedStr(template.from) + "';\n";
        code += "let $cc = '" + S25Util.nestedStr(template.cc) + "';\n";
        code += "let $bcc = '" + S25Util.nestedStr(template.bcc) + "';\n";
        code += "let $reports = '" + S25Util.nestedStr(template.reports) + "';\n";
        code += "let $iCalFile = '" + S25Util.nestedStr(template.iCalFile) + "';\n";
        code += "let $subject = '" + S25Util.nestedStr(template.subject) + "';\n";
        code += "let $body = '" + S25Util.nestedStr(template.body).replace(/\n/g, "\\n") + "';\n\n";
        code += "new Email($to, $from, $cc, $bcc, $reports,$iCalFile, $subject, $body)\n";
        return code;
    }

    public static todoTemplateToCode(template: any) {
        let code =
            "class Email { constructor(taskName, assignBy, assignTo, dueDate, comment) { this.taskName = taskName; this.assignBy = assignBy; this.assignTo = assignTo; this.dueDate = dueDate; this.comment = comment;  } }\n\n";
        code += "let $taskName = '" + S25Util.nestedStr(template.taskName) + "';\n";
        code += "let $assignBy = '" + S25Util.nestedStr(template.assignBy) + "';\n";
        code += "let $assignTo = '" + S25Util.nestedStr(template.assignTo) + "';\n";
        code += "let $dueDate ='" + S25Util.nestedStr(template.dueDate) + "';\n";
        code += "let $comment = '" + S25Util.nestedStr(template.comment).replace(/\n/g, "\\n") + "';\n\n";
        code += "new Email($taskName, $assignBy, $assignTo, $dueDate, $comment)\n";
        return code;
    }

    public static parseCode(code: string) {
        const lines = code.split("\n");
        const parsed = {} as any;
        for (let line of lines) {
            const match = line.match(/^[\s\t]*(const|let|var)[\s\t]+\$(?<variable>[\w\d]+)[\s\t]*=[\s\t]*(?<data>.+)/);
            if (!match?.groups?.variable) continue;
            const { variable, data } = match.groups as { variable: string; data: string };
            const test = data.replace(/[^}\]]+$/, "");
            if (variable === "on") parsed.on = data.replace(/.*?(all|create|edit).*/, "$1");
            else {
                if (data.startsWith("true")) parsed[variable] = true;
                else if (data.startsWith("false")) parsed[variable] = false;
                else {
                    try {
                        const temp = JSON.parse(test);
                        if (S25Util.array.isArray(temp)) {
                            parsed[variable] = temp.map((item: any) => {
                                if (typeof item === "string") return item;
                                return { ...item, itemId: item.id, itemName: item.name };
                            });
                        } else {
                            parsed[variable] = temp;
                        }
                    } catch (error: any) {}
                }
            }
        }

        const collected: EmailScenario["codeData"] = {
            on: parsed.on || "all",
            sources: {
                task: !!parsed.sources?.includes("task"),
                "event-form": !!parsed.sources?.includes("event-form"),
                express: !!parsed.sources?.includes("express"),
                "event-state-change": !!parsed.sources?.includes("event-state-change"),
                cancelTodos: !!parsed.sources?.includes("cancelTodos"),
            },
            taskTriggers: {
                assignments: !!parsed.tasksCompleteTriggers?.find((item: string) => /assign/i.test(item)),
                approvals: !!parsed.tasksCompleteTriggers?.find((item: string) => /appr/i.test(item)),
                fyis: !!parsed.tasksCompleteTriggers?.find((item: string) => /fyi/i.test(item)),
            },
            pre: {
                usePrefLocations: !!parsed.usePrePrefLocations,
                usePrefResources: !!parsed.usePrePrefResources,
                states: { include: [], exclude: [] },
                types: { include: [], exclude: [] },
                locations: { include: [], exclude: [] },
                resources: { include: [], exclude: [] },
                primaryOrgs: { include: [], exclude: [] },
                requirements: { include: [], exclude: [] },
                securityGroups: { include: [], exclude: [] },
                customAttributes: { include: [], exclude: [] },
                expectedHeadcount: parsed.preExpectedHeadcount || { operator: "none" },
            },
            post: {
                usePrefLocations: !!parsed.usePrefLocations,
                usePrefResources: !!parsed.usePrefResources,
                states: { include: [], exclude: [] },
                types: { include: [], exclude: [] },
                locations: { include: [], exclude: [] },
                resources: { include: [], exclude: [] },
                primaryOrgs: { include: [], exclude: [] },
                requirements: { include: [], exclude: [] },
                securityGroups: { include: [], exclude: [] },
                customAttributes: { include: [], exclude: [] },
                expectedHeadcount: parsed.expectedHeadcount || { operator: "none" },
            },
        };
        for (let [key, val] of Object.entries(parsed) as [string, any][]) {
            const match = key.match(/^(?<include>include|exclude)(?<pre>Pre)?(?<name>\S+)/i);
            if (!match?.groups.name) continue;
            let { include, pre, name } = match.groups;
            const preKey = pre ? "pre" : "post";
            const nameKey = S25Util.firstCharToLower(name) as ScenarioItemTypeName;
            const includeKey = include.toLowerCase() as "include" | "exclude";
            if (nameKey in collected[preKey]) {
                if (/customAttributes/i.test(nameKey)) {
                    val = val.map((v: any) => ({
                        itemId: v.id,
                        itemName: v.name,
                        itemValue: v.value,
                        additionalInfo: v.additionalInfo,
                        custAtrbType: v.type,
                        operator: v.operator,
                    }));
                }
                collected[preKey][nameKey][includeKey] = val as any;
            }
        }

        return collected;
    }

    public static hybridScenarioToCode(scenario: EmailScenario): string {
        if (scenario.mode === "hybridCode") return scenario.code;
        const sources = [];
        if (scenario.codeData.sources.task) sources.push('"task"');
        if (scenario.codeData.sources["event-form"]) sources.push('"event-form"');
        if (scenario.codeData.sources.express) sources.push('"express"');
        if (scenario.codeData.sources["event-state-change"]) sources.push('"event-state-change"');
        if (scenario.codeData.sources["cancelTodos"]) sources.push('"cancelTodos"');

        function toArrayString(data: (ScenarioItem | ScenarioAttributeItem)[]) {
            const items = (data || []).map((item) => {
                if ("custAtrbType" in item) {
                    const { itemId, itemName, itemValue, operator, additionalInfo, custAtrbType } = item;
                    return {
                        id: itemId,
                        name: itemName,
                        operator: operator,
                        value: itemValue,
                        type: custAtrbType,
                        ...(additionalInfo && { additionalInfo: additionalInfo }),
                    };
                } else {
                    return { id: item.itemId, name: item.itemName };
                }
            });
            return JSON.stringify(items);
        }

        function getHeadcount(headcount: { operator: string; value?: number; value2?: number }) {
            let { operator, value, value2 } = Object.assign({ operator: "none", value: 0, value2: 0 }, headcount);
            if (headcount.operator === "none") return JSON.stringify({ operator });
            if (headcount.operator === "between") return JSON.stringify({ operator, value, value2 });
            return JSON.stringify({ operator, value });
        }

        const taskTriggers = Object.entries(scenario.codeData.taskTriggers)
            .filter((t) => t[1])
            .map((t) => t[0]);
        const { pre, post } = scenario.codeData;
        return `// DATA
const $on                    = "${scenario.codeData.on}"; // Can be "all", "create", or "edit"
const $sources               = [${sources.join(", ")}]; // Trigger on changes to these
const $tasksCompleteTriggers = ${JSON.stringify(
            taskTriggers,
        )}; // Can be "Assignments" (AP), "Approvals" (NP), or "FYIs" (NP)

// Post
const $includeStates              = ${toArrayString(post.states.include)};
const $excludeStates              = ${toArrayString(post.states.exclude)};
const $includeTypes               = ${toArrayString(post.types.include)};
const $excludeTypes               = ${toArrayString(post.types.exclude)};
const $usePrefLocations           = ${post.usePrefLocations}; // Include preference locations for location criteria
const $includeLocations           = ${toArrayString(post.locations.include)};
const $excludeLocations           = ${toArrayString(post.locations.exclude)};
const $usePrefResources           = ${post.usePrefResources}; // Include preference resources for resource criteria
const $includeResources           = ${toArrayString(post.resources.include)};
const $excludeResources           = ${toArrayString(post.resources.exclude)};
const $includePrimaryOrgs         = ${toArrayString(post.primaryOrgs.include)};
const $excludePrimaryOrgs         = ${toArrayString(post.primaryOrgs.exclude)};
const $includeRequirements        = ${toArrayString(post.requirements.include)};
const $excludeRequirements        = ${toArrayString(post.requirements.exclude)};
const $includeSecurityGroups      = ${toArrayString(post.securityGroups.include)};
const $excludeSecurityGroups      = ${toArrayString(post.securityGroups.exclude)};
const $includeCustomAttributes    = ${toArrayString(post.customAttributes.include)};
const $excludeCustomAttributes    = ${toArrayString(post.customAttributes.exclude)};
const $expectedHeadcount          = ${getHeadcount(post.expectedHeadcount)}; 

// Pre
const $includePreStates           = ${toArrayString(pre.states.include)};
const $excludePreStates           = ${toArrayString(pre.states.exclude)};
const $includePreTypes            = ${toArrayString(pre.types.include)};
const $excludePreTypes            = ${toArrayString(pre.types.exclude)};
const $usePrePrefLocations        = ${!!pre.usePrefLocations}; // Include pre-preference locations for pre-location criteria
const $includePreLocations        = ${toArrayString(pre.locations.include)};
const $excludePreLocations        = ${toArrayString(pre.locations.exclude)};
const $usePrePrefResources        = ${!!pre.usePrefResources}; // Include pre-preference resources for pre-resource criteria
const $includePreResources        = ${toArrayString(pre.resources.include)};
const $excludePreResources        = ${toArrayString(pre.resources.exclude)};
const $includePrePrimaryOrgs      = ${toArrayString(pre.primaryOrgs.include)};
const $excludePrePrimaryOrgs      = ${toArrayString(pre.primaryOrgs.exclude)};
const $includePreRequirements     = ${toArrayString(pre.requirements.include)};
const $excludePreRequirements     = ${toArrayString(pre.requirements.exclude)};
const $includePreCustomAttributes = ${toArrayString(pre.customAttributes.include)};
const $excludePreCustomAttributes = ${toArrayString(pre.customAttributes.exclude)};
const $preExpectedHeadcount       = ${getHeadcount(pre.expectedHeadcount)}; 

// LOCATIONS AND RESOURCES
const $locationData    = $usePrefLocations    ? $pro.vars.locations.concat($pro.vars.prefLocations)       : $pro.vars.locations;
const $preLocationData = $usePrePrefLocations ? $pro.vars.preLocations.concat($pro.vars.prePrefLocations) : $pro.vars.preLocations;
const $resourceData    = $usePrefResources    ? $pro.vars.resources.concat($pro.vars.prefResources)       : $pro.vars.resources;
const $preResourceData = $usePrePrefResources ? $pro.vars.preResources.concat($pro.vars.prePrefResources) : $pro.vars.preResources;

// HELP FUNCTIONS
const $hasId  = (array, id) => !!array?.find(item => item.id === id);   // Passes if array contains an item with the specified ID
const $testId = (include, exclude, id)  => (!include.length || $hasId(include, id)) && (!exclude.length || !$hasId(exclude, id)); // Passes if the event has the included IDs and does not have the excluded IDs
const $testIntersection = (include, exclude, items) => (!include.length || $pro.util.intersects(include, items)) && (!exclude.length || !$pro.util.intersects(exclude, items)); // Passes if the event has any of the included IDs and does not have any of the excluded IDs
const $testAttributes   = (include, exclude, items) => (!include.length || $pro.vars.testAttributes(include, items)) && (!exclude.length || !$pro.vars.testAttributes(exclude, items)); // Passes if the event matches any of the included attributes and does not match any of the excluded attributes

// TESTS
const $versionTest            = $on === 'all' || $on === $pro.vars.actionType;
const $sourceTest             = !$sources.length || $pro.vars.source === "all" || $sources.includes($pro.vars.source);
const $stateTest              = $testId($includeStates,    $excludeStates,    $pro.vars.state);
const $preStateTest           = $testId($includePreStates, $excludePreStates, $pro.vars.preState);
const $typeTest               = $testId($includeTypes,    $excludeTypes,    $pro.vars.eventTypeId);
const $preTypeTest            = $testId($includePreTypes, $excludePreTypes, $pro.vars.preEventTypeId);
const $locationTest           = $testIntersection($includeLocations,    $excludeLocations,    $locationData);
const $preLocationTest        = $testIntersection($includePreLocations, $excludePreLocations, $preLocationData);
const $resourceTest           = $testIntersection($includeResources,    $excludeResources,    $resourceData);
const $preResourceTest        = $testIntersection($includePreResources, $excludePreResources, $preResourceData);
const $primaryOrgTest         = $testId($includePrimaryOrgs,    $excludePrimaryOrgs,    $pro.vars.primaryOrganization?.itemId);
const $prePrimaryOrgTest      = $testId($includePrePrimaryOrgs, $excludePrePrimaryOrgs, $pro.vars.prePrimaryOrganization?.itemId);
const $requirementsTest       = $testIntersection($includeRequirements,    $excludeRequirements,    $pro.vars.requirements);
const $preRequirementsTest    = $testIntersection($includePreRequirements, $excludePreRequirements, $pro.vars.preRequirements);
const $securityGroupTest      = $testId($includeSecurityGroups, $excludeSecurityGroups, $pro.vars.currentGroupId);
const $customAttributeTest    = $testAttributes($includeCustomAttributes,    $excludeCustomAttributes,    $pro.vars.customAttributeData);
const $preCustomAttributeTest = $testAttributes($includePreCustomAttributes, $excludePreCustomAttributes, $pro.vars.preCustomAttributeData);
const $taskTriggerTest        = $pro.vars.testTaskTriggers($tasksCompleteTriggers);
const $expectedHeadcountTest     = $pro.vars.testHeadcount($pro.vars.expectedHeadcounts, $expectedHeadcount);
const $preExpectedHeadcountTest  = $pro.vars.testHeadcount($pro.vars.preExpectedHeadcounts, $preExpectedHeadcount);

// Pass if all tests pass
$versionTest && $sourceTest && $stateTest && $preStateTest && $typeTest && $preTypeTest && $locationTest && $preLocationTest && $resourceTest && $preResourceTest && $primaryOrgTest && $prePrimaryOrgTest && $requirementsTest &&  $preRequirementsTest &&  $securityGroupTest &&  $customAttributeTest &&  $preCustomAttributeTest && $taskTriggerTest && $expectedHeadcountTest && $preExpectedHeadcountTest
`;
    }

    public static scenarioToCode(scenario: EmailScenario) {
        const hybridScenario = BpeUtil.scenarioGetData(scenario);
        return this.hybridScenarioToCode(hybridScenario);
    }

    public static scenarioToPreviewCode(scenario: EmailScenario): string {
        if (scenario.mode === "code" || scenario.mode === "hybridCode") return scenario.code;
        if (scenario.mode === "form") scenario = BpeUtil.legacyScenarioFormToHybrid(scenario);
        else if (scenario.mode === "hybridForm") {
            scenario = S25Util.deepCopy(scenario);
            scenario.codeData ??= BpeUtil.parseCode(scenario.code);
        }
        scenario.codeData.post.securityGroups.include = []; //don't check sec group for preview
        scenario.codeData.on = "all"; //run on all for preview
        scenario.codeData.sources = {}; //ignore all sources for preview
        scenario.codeData.taskTriggers = { assignments: false, approvals: false, fyis: false }; // Ignore task triggers
        return this.hybridScenarioToCode(scenario);
    }

    public static formTemplateModel(
        itemId: number,
        itemName: string,
        isManual: boolean,
        iCalFile: boolean,
        mode: string,
        to: any,
        from: string,
        cc: any,
        bcc: any,
        reports: any,
        subject: any,
        body: any,
        code: any,
    ) {
        return {
            itemId: itemId,
            itemName: itemName,
            isManual: isManual,
            iCalFile: iCalFile,
            mode: mode,
            to: to,
            from: from,
            cc: cc,
            bcc: bcc,
            reports: reports,
            subject: subject,
            body: body,
            code: code,
        };
    }

    public static formTemplateModelFromSelf(self: any) {
        return BpeUtil.formTemplateModel(
            self.itemId,
            self.itemName,
            self.isManual,
            self.iCalFile,
            self.mode,
            self.to,
            self.from,
            self.cc,
            self.bcc,
            self.reports,
            self.subject,
            self.body,
            self.code,
        );
    }

    public static formScenarioModel(
        itemId: number,
        itemName: string,
        mode: string,
        isActive: boolean,
        isScheduled: boolean,
        inclLocPref: any,
        inclResPref: any,
        onAction: any,
        onTaskAction: any,
        onEventFormAction: any,
        onExpressAction: any,
        onEventStateChangeAction: any,
        eventStates: any,
        preEventStates: any,
        eventTypes: any,
        locations: any,
        resources: any,
        organizations: any,
        requirements: any,
        attributes: any,
        securityGroups: any,
        code: any,
        templates: any,
    ) {
        return {
            itemId: itemId,
            itemName: itemName,
            mode: mode,
            isActive: isActive,
            isScheduled: isScheduled,
            inclLocPref: inclLocPref,
            inclResPref: inclResPref,
            onAction: onAction,
            onTaskAction: onTaskAction,
            onEventFormAction: onEventFormAction,
            onExpressAction: onExpressAction,
            onEventStateChangeAction: onEventStateChangeAction,
            eventStates: eventStates,
            preEventStates: preEventStates,
            eventTypes: eventTypes,
            locations: locations,
            resources: resources,
            organizations: organizations,
            requirements: requirements,
            attributes: attributes,
            securityGroups: securityGroups,
            code: code,
            templates: templates,
        };
    }

    public static formScenarioModelFromSelf(self: any) {
        return BpeUtil.formScenarioModel(
            self.itemId,
            self.itemName,
            self.mode,
            self.isActive,
            self.isScheduled,
            self.inclLocPref,
            self.inclResPref,
            self.onAction,
            self.onTaskAction,
            self.onEventFormAction,
            self.onExpressAction,
            self.onEventStateChangeAction,
            self.eventStatesBean.selectedItems,
            self.preEventStatesBean.selectedItems,
            self.eventTypesBean.selectedItems,
            self.locationsBean.selectedItems,
            self.resourcesBean.selectedItems,
            self.organizationsBean.selectedItems,
            self.requirementsBean.selectedItems,
            self.attributes,
            self.securityGroupsBean.selectedItems,
            self.code,
            self.templatesBean.selectedItems,
        );
    }

    public static util: any = {
        getCustomAttributeValue: function (custAttrId: number, eventData: any): any {
            return S25Util.propertyGetVal(
                S25Util.propertyGetParentWithChildValue(eventData, "attribute_id", custAttrId),
                "attribute_value",
            );
        },
        parseReservationPreferences: function (eventData: any): any {
            return S25Util.parseSpaceResourcePreferences(
                S25Util.propertyGetVal(S25Util.propertyGetParentWithChildValue(eventData, "text_type_id", 9), "text"),
            );
        },
        shallowIntersection: S25Util.array.shallowIntersection,
        toBool: function (bool: boolean): boolean {
            return S25Util.toBool(bool);
        },
        toDateTimeString: function (value: any) {
            return S25Util.date.toS25ISODateTimeStr(value, false);
        },
        toDateString: function (value: any) {
            return S25Util.date.toS25ISODateStr(value);
        },
        toTimeString: function (value: any) {
            return value && "1900-01-01T" + S25Util.date.toS25ISOTimeStr(value);
        },
        intersects: function <T extends { id?: number; itemId?: number }[]>(arr1: T, arr2: T) {
            if (!arr1.length || !arr2.length) return false;
            const arr1Ids = new Set(arr1.map((item) => item.id || item.itemId));
            return !!arr2.find((item) => {
                const id = item.id || item.itemId;
                return arr1Ids.has(id);
            });
        },
    };

    public static replaceVariables(
        variables: any,
        str: string,
        skipNestedStr?: any,
        keyValues?: any,
        source?: "document" | "template" | "scenario",
    ) {
        jSith.forEach(keyValues, function (key, obj) {
            let regExKey = key.replace(/\./g, "\\.");
            let arithmeticMatcher = "(\\s[\\s\\+\\-\\*\\.\\%\\/0-9dhm]*?)";
            let dateFilterMatcher = "(\\|[\\s\\:\\-\\.\\\\/\\,a-z]*){0,1}";
            let replaceVarWithValue = new RegExp(
                "{{\\$+pro\\.vars\\." +
                    regExKey +
                    arithmeticMatcher +
                    dateFilterMatcher +
                    "}}|{{\\$+pro\\.vars\\." +
                    regExKey +
                    "}}",
                "ig",
            );
            let objStr = S25Util.stringify(obj);
            str = str.replace(replaceVarWithValue, function (match, arithmetic, dateFilter) {
                let replStr: any = objStr; //get local value of value string so we don't double/triple mutate objStr w/ math ops
                let isCurrency = match.indexOf("$$") > -1; //check if we should tranform as currency
                match = match.replace(/\$+/, "$"); //undo any extra $'s used to indicate isCurrency
                if (dateFilter) {
                    match = match.replace(dateFilter, "");
                    dateFilter = dateFilter.replace("|", "").trim();
                }
                if (arithmetic && !arithmetic.startsWith(".")) {
                    //if we have math...
                    try {
                        match = match.replace("{{", "").replace("}}", ""); //replace brackets in match
                        const val = keyValues[key];
                        if (
                            !isCurrency &&
                            (S25Util.date.isDate(val) ||
                                (key.indexOf("dates.") > -1 && (key.endsWith("String") || key.endsWith("Date"))))
                        ) {
                            //if date/time string
                            let origDateKey = key.endsWith("Date")
                                ? key
                                : key.replace(/(Date)*(Time)*String/, "") + "Date"; //get actual date object
                            let origDate = keyValues[origDateKey];
                            match = match.replace(/(\d+)d/gi, "($1 * 1000 * 60 * 60 * 24)"); //replace convenience markers for days/hours/minutes w/ ms
                            match = match.replace(/(\d+)h/gi, "($1 * 1000 * 60 * 60)");
                            match = match.replace(/(\d+)m/gi, "($1 * 1000 * 60)");
                            let newDate = new Date(eval(match.replace("$pro.vars." + key, origDate.getTime()))); //calc new date
                            if (key.endsWith("DateTimeString")) {
                                //format new date
                                replStr = S25Datefilter.transform(newDate, variables.dateTimeFormat);
                            } else if (key.endsWith("DateString")) {
                                replStr = S25Datefilter.transform(newDate, variables.dateFormat);
                            } else if (key.endsWith("TimeString")) {
                                replStr = S25Datefilter.transform(newDate, variables.timeFormat);
                            } else if (key.endsWith("Date")) {
                                replStr = S25Util.date.toS25ISODateTimeStr(newDate);
                            }
                        } else if (S25Util.isNumeric(replStr)) {
                            //numbers
                            replStr = Math.round(eval(match.replace("$pro.vars." + key, replStr)) * 100) / 100;
                        }
                    } catch (e) {}
                }

                if (isCurrency) {
                    try {
                        replStr = FormatService.toDollars(replStr);
                    } catch (e) {}
                } else if (dateFilter && !key.endsWith("String")) {
                    try {
                        let filter = dateFilter;
                        if (dateFilter === "dateString") filter = keyValues.dateFormat;
                        else if (dateFilter === "timeString") filter = keyValues.timeFormat;
                        else if (dateFilter === "dateTimeString") filter = keyValues.dateTimeFormat;
                        replStr = S25Datefilter.transform(replStr, filter);
                    } catch (e) {}
                }

                return skipNestedStr ? replStr : S25Util.nestedStr(replStr);
            });
        });

        //replace unused variables
        let replaceUnusedVars = new RegExp("{{\\$pro\\.vars.*?}}", "ig");
        str = str.replace(replaceUnusedVars, "");

        return str;
    }

    public static replaceLoops(
        variables: any,
        str: string,
        skipNestedStr?: any,
        keyValues?: any,
        source?: "document" | "template" | "scenario",
    ) {
        //process for-loops, eg: {{for:$pro.vars.occurrences:rsrvState:State||dates.rsrvStartDate|mediumDate:Start||rsrvStateName:State:end-for}}
        let loopRegex = new RegExp("{{for:\\s*(\\$pro.*?)\\s*:end-for}}", "ig");
        return str.replace(loopRegex, function (match, loop) {
            if (loop) {
                let parts = loop.split(":");
                if (parts && parts.length > 1) {
                    let arrKey = parts.shift().replace("$pro.vars.", ""); //eg, $pro.vars.occurrences --> occurrences
                    let arr = keyValues[arrKey];

                    let rowDefStr = parts.join(":");
                    let rawRowDefParts = rowDefStr.split("||");
                    let rowDefParts: string[] = [];
                    if (rawRowDefParts && rawRowDefParts.length) {
                        for (let i = 0; i < rawRowDefParts.length; i++) {
                            if (rawRowDefParts[i].startsWith("for:")) {
                                let forParts = [rawRowDefParts[i]],
                                    fors = 1,
                                    ends = rawRowDefParts[i].indexOf(":end-for") > -1 ? 1 : 0;
                                while (i < rawRowDefParts.length && fors !== ends) {
                                    i++;
                                    forParts.push(rawRowDefParts[i]);
                                    if (rawRowDefParts[i].startsWith("for:")) {
                                        fors++;
                                    } else if (rawRowDefParts[i].indexOf(":end-for") > -1) {
                                        ends++;
                                    }
                                }
                                fors === ends && rowDefParts.push(forParts.join("||"));
                            } else {
                                rowDefParts.push(rawRowDefParts[i]);
                            }
                        }

                        let rowDefs = rowDefParts.map((r: any) => {
                            let isFor = r.startsWith("for:");
                            let rParts = r.split(":");
                            let header, prop;

                            if (isFor) {
                                header = rParts.pop();
                                prop = rParts.join(":");
                            } else {
                                prop = rParts[0].trim();
                                header =
                                    rParts[1] ||
                                    prop
                                        .split(".")
                                        .pop()
                                        .split(/[-%|+*]/)
                                        .shift() ||
                                    "";
                            }

                            return {
                                prop: prop,
                                header: header,
                            };
                        });

                        let headers = rowDefs.map((r: any) => {
                            return r.header;
                        });

                        let lastRowKeyValues = {};
                        return buildTable(
                            headers,
                            arr,
                            (row: any, i: number) => {
                                let prop = rowDefs[i].prop;

                                let curlyVar = "{{";
                                if (prop.startsWith("(") || prop.startsWith("for:")) {
                                    //conditional expression or nested table
                                    curlyVar += prop;
                                } else {
                                    if (prop.startsWith("$")) {
                                        curlyVar += prop;
                                    } else {
                                        curlyVar += "$pro.vars._row." + prop;
                                    }
                                }
                                curlyVar += "}}";

                                return BpeUtil.interpolateString(
                                    null,
                                    curlyVar,
                                    skipNestedStr,
                                    keyValues,
                                    source,
                                ).replace(/\n/g, "<br/>");
                            },
                            (row: any) => {
                                jSith.forEach(lastRowKeyValues, (key: any) => {
                                    keyValues[key] = null;
                                });
                                let rowKeyValues = variableKeyValues({ _row: row });
                                lastRowKeyValues = rowKeyValues;
                                keyValues = S25Util.extend(keyValues, rowKeyValues);
                            },
                            source,
                        );
                    }
                }
            }

            return "";
        });
    }

    public static replaceLists(
        variables: any,
        str: string,
        skipNestedStr?: any,
        keyValues?: any,
        source?: "document" | "template" | "scenario",
    ) {
        // {{list: <source> : <data> <math> | <format> }}
        return str.replace(
            /{{\s*list:\s*\$pro.vars.(?:(.+?)\s*:\s*(.*?))\s*}}/gi,
            (match, array: string, expression: string) => {
                const arr = keyValues?.[array]; // Get <source>
                if (!arr) return "";
                const keys = expression.match(/^\S+/)[0]; // Get <data>
                // Map source list to final values
                const list = arr.map((item: any) => {
                    // Get data value
                    let val = item;
                    for (let key of keys.split(".")) val = val[key];
                    // Parse <data> <math> | <format>
                    const tempKey = `_list.${keys}`;
                    keyValues[tempKey] = val;
                    const parsed = BpeUtil.interpolateString(
                        null,
                        `{{$pro.vars._list.${expression}}}`,
                        skipNestedStr,
                        keyValues,
                        source,
                    ).replace(/\n/g, "<br/>");
                    delete keyValues[tempKey];
                    return parsed;
                });

                const filteredList = list.filter((item: any) => item);
                const containsComma = !!filteredList.find((item: any) => String(item).includes(","));
                return filteredList.join(containsComma ? "; " : ", ");
            },
        );
    }

    public static replaceConditionals(
        variables: any,
        str: string,
        skipNestedStr?: any,
        keyValues?: any,
        source?: "document" | "template" | "scenario",
    ) {
        let replaceExpressionWithValue = new RegExp("{{\\s*(\\(.*?\\))\\s*}}", "ig");
        return str.replace(replaceExpressionWithValue, function (match, expression) {
            if (expression) {
                //the matching mechanism is double escaping quotes, so undo that bc it breaks evaluation
                expression = expression.replace(new RegExp(/(\\)+"/, "g"), '"').replace(new RegExp(/(\\)+'/, "g"), "'");
                // Unescape symbols escaped by the rich text editor
                expression = S25Util.unescapeXml(expression);
                // Variables need to be replaced after escaping, but before executing conditional
                expression = BpeUtil.replaceVariables(variables, expression, skipNestedStr, keyValues, source);
                const $pro = { vars: variables }; // Provides "$pro.vars" to conditional logic
                let val = eval(expression);
                return skipNestedStr ? val : S25Util.nestedStr(val);
            } else {
                return "";
            }
        });
    }

    public static interpolateString(
        variables: any,
        str: string,
        skipNestedStr?: any,
        keyValues?: any,
        source?: "document" | "template" | "scenario",
    ) {
        keyValues = keyValues || variableKeyValues(variables);

        str = BpeUtil.replaceLists(variables, str, skipNestedStr, keyValues, source);
        str = BpeUtil.replaceLoops(variables, str, skipNestedStr, keyValues, source);
        str = BpeUtil.replaceConditionals(variables, str, skipNestedStr, keyValues, source);
        str = BpeUtil.replaceVariables(variables, str, skipNestedStr, keyValues, source);

        return str;
    }

    public static processSpRsRsrv(
        rsrv: any,
        locationHash: any,
        locations: any[],
        resourceHash?: any,
        resources?: any,
        profile?: any,
    ) {
        jSith.forEach(rsrv.space_reservation, function (key, spRsrv) {
            if (!locationHash[spRsrv.space_id]) {
                locationHash[spRsrv.space_id] = true;
                locations.push({
                    itemId: parseInt(spRsrv.space_id),
                    itemName: strVar(S25Util.propertyGetVal(spRsrv, "space_name")),
                    itemFormalName: strVar(S25Util.propertyGetVal(spRsrv, "formal_name")) || "",
                    instructions: strVar(spRsrv.space_instructions),
                    attendance: spRsrv.attendance,
                    layout: strVar(spRsrv.layout_name),
                    layoutCapacity: spRsrv.selected_layout_capacity,
                    layoutDefaultCapacity: spRsrv.default_layout_capacity,
                    maxCapacity: S25Util.coalesce(parseInt(S25Util.propertyGetVal(spRsrv, "max_capacity")), ""),
                    partitionName: strVar(S25Util.propertyGetVal(spRsrv, "partition_name")) || "",
                    ...(profile ? { profileName: strVar(profile.profile_name) } : {}),
                });
            }
        });

        jSith.forEach(rsrv.resource_reservation, function (key, rsRsrv) {
            let resourceName = S25Util.propertyGetVal(rsRsrv, "resource_name");
            if (!resourceHash[rsRsrv.resource_id]) {
                resourceHash[rsRsrv.resource_id] = true;
                resources.push({
                    itemId: parseInt(rsRsrv.resource_id),
                    itemName: strVar(resourceName),
                    instructions: strVar(rsRsrv.resource_instructions),
                    quantity: rsRsrv.quantity,
                    ...(profile ? { profileName: strVar(profile.profile_name) } : {}),
                });
            }
        });
    }

    /**
     *
     * @param eventData -
     * @param profileId - ID of the changed profile
     * @param preEventData - Previous event Data
     * @param source "event-form", "express", "event-state-change", "tasks", "todos"
     * @param getFullTaskData
     * @param todoId
     * @returns
     */
    public static getVariables(
        eventData: any,
        profileId: any,
        preEventData: any,
        source?: BpeScenarioTypes,
        getFullTaskData?: any,
        todoId?: number,
    ): Promise<BpeVarsI> {
        eventData.profile = eventData.profile || [];

        let todos = S25Util.propertyGetVal(eventData, "todo");

        return S25Util.all({
            taskPrep: getFullTaskData && todos && getTodoVars(todos, todoId),
            currentGroupId: UserprefService.getGroupId(),
            currentContactName: ContactService.getCurrentNameStyled(),
            currentContactId: UserprefService.getContactId(),
            timeFormat: UserprefService.getS25Timeformat(),
            dateFormat: UserprefService.getS25Dateformat(),
            dateTimeFormat: UserprefService.getS25DateTimeformat(),
        }).then(function (resp) {
            let todo = resp.taskPrep;
            let requester = S25Util.propertyGetParentWithChildValue(eventData, "role_id", S25Const.requestorRole.event);
            let scheduler = S25Util.propertyGetParentWithChildValue(eventData, "role_id", S25Const.schedulerRole.event);
            let organizations = S25Util.propertyGetVal(eventData, "organization") || [];
            organizations = S25Util.array.forceArray(organizations);
            let orgVars = organizations.reduce((org: any, obj: any) => {
                org[obj.organization_id] = {
                    itemId: parseInt(obj.organization_id),
                    itemName: strVar(obj.organization_name),
                    itemTitle: strVar(obj.organization_title),
                    primary: obj.primary,
                    contacts: {},
                    rating: strVar(obj.organization_details?.organization_rating),
                    accountNumber: strVar(obj.organization_details?.account_number),
                    type: strVar(obj.organization_details?.organization_type?.organization_type_name),
                    typeId: obj.organization_details?.organization_type?.organization_type_id,
                };

                let addresses = (obj.address || []).reduce((addr: any, addrObj: any) => {
                    let addrTypeName = parseInt(addrObj.address_type) === 1 ? "adminAddress" : "billingAddress";
                    addr[addrTypeName] = {
                        address: strVar(addrObj.formatted_address).replace(/(?:\r\n|\r|\n)/g, "<br/>"),
                    };
                    return addr;
                }, {});

                org[obj.organization_id].billingAddress = addresses.billingAddress;
                org[obj.organization_id].adminAddress = addresses.adminAddress;

                org[obj.organization_id].contacts = (obj.contact || []).reduce((cont: any, contObj: any) => {
                    cont[contObj.contact_role_id] = {
                        itemId: parseInt(contObj.contact_id),
                        itemName: strVar(contObj.contact_name),
                        itemTitle: strVar(contObj.contact_title),
                        role: strVar(contObj.contact_role),
                        email: strVar(contObj.contact_email),
                        phone: strVar(contObj.contact_phone),
                        fax: strVar(contObj.contact_fax),
                        address: strVar(contObj.contact_formatted_address).replace(/(?:\r\n|\r|\n)/g, "<br/>"),
                    };
                    return cont;
                }, {});
                return org;
            }, {});
            let primaryOrganization = S25Util.propertyGetParentWithChildValue(orgVars, "primary", "T");

            const organizationsList = organizations
                .map((org: any) => ({
                    id: org.organization_id,
                    name: strVar(org.organization_name),
                    title: strVar(org.organization_title),
                    isPrimary: org.primary === "T" ? "Yes" : "",
                    accountNumber: strVar(org.organization_details?.account_number),
                    rating: strVar(org.organization_details?.organization_rating),
                    type: strVar(org.organization_details?.organization_type?.organization_type_name),
                }))
                .sort((a: any) => (a.isPrimary === "Yes" ? -1 : 1));

            let billItems = S25Util.propertyGet(eventData, "bill_item") || [];

            const dollar = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
            const pricing = eventData.pricing?.data.items[0].billing;
            const occurrenceSubtotals: Map<
                number,
                OccurrenceSubtotal & {
                    locationsTotal: number;
                    locationsList: number;
                    resourcesTotal: number;
                    resourcesList: number;
                    locations: Map<number, { total: number; list: number }>;
                    resources: Map<number, { total: number; list: number }>;
                }
            > = new Map();
            if (pricing) {
                for (const subtotal of pricing.subtotals[0].occurrence || []) {
                    occurrenceSubtotals.set(subtotal.rsrvId, {
                        ...subtotal,
                        locations: new Map(),
                        resources: new Map(),
                    });
                }

                for (const item of pricing.lineItems) {
                    if (![lineItemType.LOCATION, lineItemType.RESOURCE].includes(item.itemType)) continue;
                    for (const occ of item.occurrences) {
                        const data = occurrenceSubtotals.get(occ.rsrvId);
                        if (!data) continue; // Should never happen

                        if (item.itemType === lineItemType.LOCATION) {
                            data.locationsTotal = (data.locationsTotal || 0) + occ.total;
                            data.locationsList = (data.locationsList || 0) + occ.listPrice;
                            const locationData = data.locations.get(item.itemId) || { total: 0, list: 0 };
                            locationData.total = (locationData.total || 0) + occ.total;
                            locationData.list = (locationData.list || 0) + occ.listPrice;
                            data.locations.set(item.itemId, locationData);
                        } else {
                            data.resourcesTotal = (data.resourcesTotal || 0) + occ.total;
                            data.resourcesList = (data.resourcesList || 0) + occ.listPrice;
                            const resourceData = data.resources.get(item.itemId) || { total: 0, list: 0 };
                            resourceData.total = (resourceData.total || 0) + occ.total;
                            resourceData.list = (resourceData.list || 0) + occ.listPrice;
                            data.resources.set(item.itemId, resourceData);
                        }
                    }
                }
            }

            let grandTotal = PricingService.agg.totalCharge.total(billItems);

            let prefJson = BpeUtil.util.parseReservationPreferences(eventData);
            let startDate: any = null,
                endDate: any = null;
            let eventStartDate: any = null,
                eventEndDate: any = null;
            let preEventDate: any = null,
                postEventDate: any = null;
            let additionalTime: any = {};
            let descriptionNode = S25Util.propertyGetParentWithChildValue(eventData, "text_type_id", "1");
            let internalNoteNode = S25Util.propertyGetParentWithChildValue(eventData, "text_type_id", "2");
            let confirmationNoteNode = S25Util.propertyGetParentWithChildValue(eventData, "text_type_id", "3");
            let customAttributes: any = {};
            let customAttributesList = [];
            if (eventData.custom_attribute) {
                const getAttributeValue = (obj: any) => {
                    if (["S", "X", "R"].indexOf(obj.attribute_type) > -1) {
                        let attributes = [obj.attribute_value];
                        if (!!obj.multi_val) {
                            const [items, err] = S25Util.parseJson<{ item: string }[]>(obj.attribute_value);
                            if (err) return "";
                            attributes = S25Util.array.forceArray(items).map(({ item }) => item);
                        }
                        return attributes.map((a) => strVar(a).replace(/\r\n|\r|\n/g, "<br/>")).join("; ");
                    } else if (["B"].indexOf(obj.attribute_type) > -1) {
                        return S25Util.toBool(obj.attribute_value) ? "Yes" : "No";
                    } else if ([2].indexOf(obj.attribute_type) > -1) {
                        return obj.attribute_organization ? obj.attribute_organization.attribute_organization_name : "";
                    } else if ([3].indexOf(obj.attribute_type) > -1) {
                        return obj.attribute_contact ? obj.attribute_contact.attribute_contact_name : "";
                    } else if ([4].indexOf(obj.attribute_type) > -1) {
                        return obj.attribute_space ? obj.attribute_space.attribute_space_name : "";
                    } else if ([6].indexOf(obj.attribute_value) > -1) {
                        return obj.attribute_resource ? obj.attribute_resource.attribute_resource_name : "";
                    } else {
                        return obj.attribute_value;
                    }
                };

                customAttributes = eventData.custom_attribute.reduce((ca: any, obj: any) => {
                    ca[obj.attribute_id] = getAttributeValue(obj);
                    return ca;
                }, {});

                customAttributesList = eventData.custom_attribute.map((attribute: any) => {
                    return {
                        isActive: attribute.attribute_defn_state ? "Yes" : "No",
                        id: attribute.attribute_id,
                        name: strVar(attribute.attribute_name),
                        type: strVar(attribute.attribute_type_name),
                        value: getAttributeValue(attribute),
                    };
                });
            }

            let categories = [];
            if (eventData.category) {
                categories = eventData.category.map((category: any) => ({
                    id: category.category_id,
                    name: strVar(category.category_name),
                    isActive: category.category_defn_state ? "Yes" : "No",
                }));
            }

            let billItemList;
            const profileNames: Record<number, string> = {};
            for (let profile of eventData.profile || []) profileNames[profile.profile_id] = profile.profile_name;
            const organizationNames: Record<number, string> = {};
            for (let org of eventData.organization || [])
                organizationNames[org.organization_id] = org.organization_name;
            if (eventData.bill_items) {
                billItemList = eventData.bill_items.map((item: BpeBillItem) => {
                    let adjustment: string;
                    if (item.adjustmentPercent !== undefined) adjustment = `${item.adjustmentPercent}%`;
                    else if (item.adjustmentAmt !== undefined) adjustment = dollar.format(item.adjustmentAmt);

                    const total = item.totalCharge || item.total;
                    const price = item.itemName === "Adjustment" ? "" : dollar.format(item.price || 0);
                    const listPrice = item.itemName === "Adjustment" ? "" : dollar.format(item.listPrice || 0);
                    const type = ["", "Event", "Requirement", "Location", "Resource"][item.itemType] || "";

                    return {
                        adjustmentAmount: adjustment || "",
                        adjustmentName: strVar(item.adjustmentName),
                        id: item.itemId || "",
                        name: strVar(item.itemName),
                        type,
                        profile: strVar(profileNames[item.profileId] || ""),
                        chargeTo: strVar(organizationNames[item.chargeToId] || ""),
                        creditAccount: strVar(item.creditAccountNumber),
                        debitAccount: strVar(item.debitAccountNumber),
                        listPrice,
                        rateGroup: strVar(item.rateGroupName),
                        rateName: strVar(item.rateScheduleName),
                        rateDescription: strVar(item.rateDescription),
                        price,
                        totalCharge: dollar.format(total || 0),
                        totalCount: item.totalCount === -1 ? "" : item.totalCount,
                        totalTax: item.tax ? dollar.format(item.tax) : "",
                        totalTime: item.totalTime || "",
                    };
                });
            }

            let relationships = [];
            if (eventData.content) {
                relationships = eventData.content.map((item: any) => {
                    const startDate = S25Util.date.parseDropTZ(item.content_start_date);
                    const endDate = S25Util.date.parseDropTZ(item.content_end_date);
                    return {
                        id: item.content_event_id,
                        name: strVar(item.content_event_name),
                        title: strVar(item.content_event_title),
                        startDate,
                        endDate,
                    };
                });
            }

            const firstReservationDateById: any = {};
            const segments: any = [];
            for (let profile of eventData.profile || []) {
                profile.reservation = profile.reservation || [];

                let firstSetup: any = new Date();
                let firstPre: any = new Date();
                let firstStart: any = new Date();
                let lastEnd: any = new Date();
                let lastPost: any = new Date();
                let lastTakedown: any = new Date();
                for (let reservation of profile.reservation) {
                    const setup = new Date(reservation.reservation_start_dt);
                    const pre = new Date(reservation.pre_event_dt);
                    const start = new Date(reservation.event_start_dt);
                    const end = new Date(reservation.event_end_dt);
                    const post = new Date(reservation.post_event_dt);
                    const takedown = new Date(reservation.reservation_end_dt);
                    if (setup < firstSetup) firstSetup = setup;
                    if (pre < firstPre) firstPre = pre;
                    if (start < firstStart) firstStart = start;
                    if (end > lastEnd) lastEnd = end;
                    if (post > lastPost) lastPost = post;
                    if (takedown > lastTakedown) lastTakedown = takedown;
                    firstReservationDateById[reservation.reservation_id] = reservation.reservation_start_dt;
                }

                segments.push({
                    name: strVar(profile.profile_name),
                    comments: strVar(profile.profile_comments),
                    expected: profile.expected_count,
                    registered: profile.registered_count,
                    setupDuration: S25Util.getDurationString(
                        S25Util.parseXmlDuration(profile.setup_profile?.setup_tm || "P") / S25Const.ms.min,
                    ),
                    takedownDuration: S25Util.getDurationString(
                        S25Util.parseXmlDuration(profile.takedown_profile?.tdown_tm || "P") / S25Const.ms.min,
                    ),
                    preEventDuration: S25Util.getDurationString(
                        S25Util.parseXmlDuration(profile.pre_event || "P") / S25Const.ms.min,
                    ),
                    postEventDuration: S25Util.getDurationString(
                        S25Util.parseXmlDuration(profile.post_event || "P") / S25Const.ms.min,
                    ),
                    eventDuration: S25Util.getDurationString((lastEnd - firstStart) / S25Const.ms.min),
                    preToPostDuration: S25Util.getDurationString((lastPost - firstPre) / S25Const.ms.min),
                    setupToTakedownDuration: S25Util.getDurationString((lastTakedown - firstSetup) / S25Const.ms.min),
                    startDate: firstSetup,
                    endDate: lastTakedown,
                });
            }

            let tasks = [];
            if (eventData.approval) {
                const firstDate = new Date(eventData.start_date);
                if (eventData.todo) {
                    tasks.push(
                        ...eventData.todo.map((todo: any) => {
                            const assignedTo = TaskService.formTaskContactString(
                                todo.cur_assigned_to,
                                todo.cur_assigned_to_id,
                                resp.currentContactId,
                            );
                            return {
                                id: todo.todo_id,
                                comment: strVar(todo.todo_description),
                                name: strVar(todo.todo_name),
                                state: TaskService.taskStateToStateText(todo.cur_todo_state, 5),
                                type: todo.todo_type_name,
                                flagged: todo.read === "F" ? "Yes" : "",
                                assignedTo: strVar(assignedTo),
                                respondByDate: S25Util.date.parseDropTZ(todo.due_dt),
                                firstDate,
                            };
                        }),
                    );
                }

                tasks.push(
                    ...eventData.approval.map((task: any) => {
                        const typeInfo = TaskService.extractApprovalTypeInfo(task, resp.currentContactId);
                        const contacts = S25Util.array.forceArray(task.approval_contact) as any;
                        const currentContact = contacts.filter(
                            (contact: any) => parseInt(contact.approval_contact_id) === resp.currentContactId,
                        )[0];
                        const flagged = currentContact?.read_state === "F" ? "Yes" : "";
                        const firstDate = firstReservationDateById[task.approval_profile_id] || eventData.start_date;
                        const assignedTo = TaskService.formTaskContactString(
                            !!currentContact,
                            resp.currentContactId,
                            task,
                        );
                        return {
                            comment: strVar(task.approval_comments),
                            id: task.approval_id,
                            name: strVar(task.approval_name),
                            state: TaskService.taskStateToStateText(task.approval_state, typeInfo.typeId),
                            type: strVar(typeInfo.typeName),
                            flagged,
                            assignedTo: strVar(assignedTo),
                            object: strVar(task.object_type_name),
                            respondByDate: S25Util.date.parseDropTZ(task.respond_by),
                            firstDate: S25Util.date.parseDropTZ(firstDate),
                        };
                    }),
                );
            }

            let contacts = [];
            if (eventData.role) {
                contacts = eventData.role.map((role: any) => {
                    return {
                        role: strVar(role.role_name),
                        roleId: role.role_id,
                        contactId: role.contact.contact_id,
                        firstName: strVar(role.contact.contact_first_name),
                        middleName: strVar(role.contact.contact_middle_name),
                        lastName: strVar(role.contact.contact_last_name),
                        name: strVar(role.contact.contact_name),
                        email: strVar(role.contact.email),
                        fax: strVar(role.contact.fax),
                        address: strVar(role.contact.formatted_address).replace(/\r\n|\r|\n/g, "<br/>"),
                        phone: strVar(role.contact.phone),
                    };
                });
            }

            let requirements: any = [];
            if (eventData.requirement) {
                requirements = eventData.requirement.map(function (r: any) {
                    return {
                        itemId: r.requirement_id,
                        itemName: strVar(r.requirement_name),
                        comment: strVar(r.req_comment),
                        count: r.requirement_count === -1 ? "" : r.requirement_count,
                        isActive: r.req_defn_state ? "Yes" : "No",
                    };
                });
            }
            let contId: any = {};
            let contName: any = {};
            let contEmail: any = {};
            let contPhone: any = {};
            let contAddress: any = {};
            let contFax: any = {};
            let contFirst: any = {};
            let contLast: any = {};
            let contMiddle: any = {};
            if (eventData.role) {
                contId = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.contact_id);
                    return co;
                }, {});
                contName = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.contact_name);
                    return co;
                }, {});
                contEmail = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.email);
                    return co;
                }, {});
                contPhone = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.phone);
                    return co;
                }, {});
                contAddress = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.formatted_address).replace(/(?:\r\n|\r|\n)/g, "<br/>");
                    return co;
                }, {});
                contFax = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.fax);
                    return co;
                }, {});
                contFirst = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.contact_first_name);
                    return co;
                }, {});
                contLast = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.contact_last_name);
                    return co;
                }, {});
                contMiddle = eventData.role.reduce((co: any, obj: any) => {
                    co[obj.role_id] = strVar(obj.contact?.contact_middle_name);
                    return co;
                }, {});
            }
            profileId = parseInt(profileId);
            let profiles = S25Util.array.forceArray(
                (profileId && S25Util.propertyGetParentWithChildValue(eventData, "profile_id", profileId)) ||
                    eventData.profile,
            );
            let stringDataArr: any[] = [],
                expectedCountArr: any[] = [],
                registeredCountArr: any[] = [],
                commentArr: any[] = [],
                defaultStringData: any = {};
            let globalOccurrences: any[] = [],
                occurrencesResources: any[] = [],
                occurrencesLocations: any[] = [],
                globalPrefOccurrences: any[] = [],
                prefOccurrencesResources: any[] = [],
                prefOccurrencesLocations: any[] = [];
            let globalLocations: any[] = [],
                globalPrefLocations: any[] = [];
            let globalResources: any[] = [],
                globalPrefResources: any[] = [];
            let typeMap: any = {
                occurrencesString: {
                    headers: ["Event Start", "Event End", "State"],
                    rowF: occRowF,
                    dataTypeBase: "occurrences",
                },
                occurrencesWithLocationsString: {
                    headers: ["Event Start", "Event End", "State", "Location"],
                    rowF: occLocRowF,
                    dataTypeBase: "occurrencesObject",
                },
                occurrencesWithResourcesString: {
                    headers: ["Event Start", "Event End", "State", "Resource"],
                    rowF: occResRowF,
                    dataTypeBase: "occurrencesObject",
                },
                occurrencesWithLocationsAndResourcesString: {
                    headers: ["Event Start", "Event End", "State", "Object Type", "Object Name"],
                    rowF: occLocResRowF,
                    dataTypeBase: "occurrencesObject",
                },
                prefLocationsString: {
                    headers: ["Name"],
                    rowF: locRowF,
                    dataTypeBase: "locations",
                },
                locationsString: {
                    headers: ["Name", "Formal Name"],
                    rowF: locRowF,
                    dataTypeBase: "locations",
                },
                resourcesString: {
                    headers: ["Name"],
                    rowF: resRowF,
                    dataTypeBase: "resources",
                },
            };

            jSith.forEach(profiles, function (key, profile) {
                let stringData: any = {};
                let profExpected =
                    S25Util.coalesce(parseInt(S25Util.propertyGetVal(profile, "expected_count")), "") + "";
                let profRegistered =
                    S25Util.coalesce(parseInt(S25Util.propertyGetVal(profile, "registered_count")), "") + "";
                let comment = S25Util.propertyGetVal(profile, "profile_comments");

                if (profExpected) {
                    expectedCountArr.push({ profileName: profile.profile_name, value: profExpected });
                }

                if (profRegistered) {
                    registeredCountArr.push({ profileName: profile.profile_name, value: profRegistered });
                }

                if (comment) {
                    commentArr.push({ profileName: profile.profile_name, value: comment });
                }

                let reservationNodes = S25Util.propertyGet(profile, "reservation");
                let locations: any[] = [],
                    locationHash = {},
                    prefLocations: any[] = [],
                    prefLocationHash = {};
                let resources: any[] = [],
                    resourceHash = {},
                    prefResources: any[] = [],
                    prefResourceHash = {};
                let occurrences: any[] = [],
                    prefOccurrences: any[] = [];
                let occurrencesObject: any[] = [],
                    prefOccurrencesObject: any[] = [];

                jSith.forEach(reservationNodes, function (key, rsrv) {
                    let rsrvState = parseInt(rsrv.reservation_state);
                    const dates = {
                        rsrvStartDate: S25Util.date.parseDropTZ(rsrv.reservation_start_dt),
                        rsrvPreEventDate: S25Util.date.parseDropTZ(rsrv.pre_event_dt),
                        rsrvEventStartDate: S25Util.date.parseDropTZ(rsrv.event_start_dt),
                        rsrvEventEndDate: S25Util.date.parseDropTZ(rsrv.event_end_dt),
                        rsrvPostEventDate: S25Util.date.parseDropTZ(rsrv.post_event_dt),
                        rsrvEndDate: S25Util.date.parseDropTZ(rsrv.reservation_end_dt),
                    };
                    const durations = {
                        setup: S25Util.getDurationString(
                            (dates.rsrvPreEventDate.getTime() - dates.rsrvStartDate.getTime()) / 60_000,
                        ),
                        preEvent: S25Util.getDurationString(
                            (dates.rsrvEventStartDate.getTime() - dates.rsrvPreEventDate.getTime()) / 60_000,
                        ),
                        event: S25Util.getDurationString(
                            (dates.rsrvEventEndDate.getTime() - dates.rsrvEventStartDate.getTime()) / 60_000,
                        ),
                        postEvent: S25Util.getDurationString(
                            (dates.rsrvPostEventDate.getTime() - dates.rsrvEventEndDate.getTime()) / 60_000,
                        ),
                        takeDown: S25Util.getDurationString(
                            (dates.rsrvEndDate.getTime() - dates.rsrvPostEventDate.getTime()) / 60_000,
                        ),
                        preToPost: S25Util.getDurationString(
                            (dates.rsrvPostEventDate.getTime() - dates.rsrvPreEventDate.getTime()) / 60_000,
                        ),
                        setupToTakedown: S25Util.getDurationString(
                            (dates.rsrvEndDate.getTime() - dates.rsrvStartDate.getTime()) / 60_000,
                        ),
                    };
                    const occSubtotals = occurrenceSubtotals.get(rsrv.reservation_id);
                    let rsrvItem = {
                        rsrvState: rsrvState,
                        rsrvStateName: rsrvStateToName[rsrvState],
                        dates,
                        durations,
                        spaceReservation: rsrv.space_reservation || [],
                        resourceReservation: rsrv.resource_reservation || [],
                        profileName: strVar(profile.profile_name),
                        occurrenceTotalListPrice: dollar.format(occSubtotals?.occurrenceListPrice || 0),
                        occurrenceTotalCharge: dollar.format(occSubtotals?.occurrenceTotalCharge || 0),
                        occurrenceTotalAdjustments: dollar.format(occSubtotals?.occurrenceAdjustments || 0),
                        occurrenceLocationsTotal: dollar.format(occSubtotals?.locationsTotal || 0),
                        occurrenceResourcesTotal: dollar.format(occSubtotals?.resourcesTotal || 0),
                        occurrenceLocationsTotalListPrice: dollar.format(occSubtotals?.locationsList || 0),
                        occurrenceResourcesTotalListPrice: dollar.format(occSubtotals?.resourcesList || 0),
                    };
                    if (!startDate || rsrvItem.dates.rsrvStartDate < startDate) {
                        startDate = rsrvItem.dates.rsrvStartDate;
                    }

                    if (!endDate || rsrvItem.dates.rsrvEndDate > endDate) {
                        endDate = rsrvItem.dates.rsrvEndDate;
                    }

                    if (!eventEndDate || rsrvItem.dates.rsrvEventEndDate > eventEndDate) {
                        eventEndDate = rsrvItem.dates.rsrvEventEndDate;
                    }
                    if (!eventStartDate || rsrvItem.dates.rsrvEventStartDate < eventStartDate) {
                        eventStartDate = rsrvItem.dates.rsrvEventStartDate;
                    }

                    if (!preEventDate || rsrvItem.dates.rsrvPreEventDate < preEventDate) {
                        preEventDate = rsrvItem.dates.rsrvPreEventDate;
                    }
                    if (!postEventDate || rsrvItem.dates.rsrvPostEventDate > postEventDate) {
                        postEventDate = rsrvItem.dates.rsrvPostEventDate;
                    }

                    generateDateStrings(rsrvItem, resp.timeFormat, resp.dateFormat, resp.dateTimeFormat);
                    occurrences.push(rsrvItem);
                    occurrencesResources.push(
                        ...rsrvItem.resourceReservation.map((resource: any) => {
                            const pricing = occSubtotals?.resources.get(resource.resource_id);
                            return {
                                ...rsrvItem,
                                ...resource,
                                type: "Resource",
                                name: strVar(resource.resource.resource_name),
                                id: resource.resource_id,
                                instructions: strVar(resource.resource_instructions),
                                resourceOccurrenceTotal: dollar.format(pricing?.total || 0),
                                resourceOccurrenceListPrice: dollar.format(pricing?.list || 0),
                                locationOccurrenceTotal: "",
                                locationOccurrenceListPrice: "",
                            };
                        }),
                    );
                    occurrencesLocations.push(
                        ...rsrvItem.spaceReservation.map((space: any) => {
                            const pricing = occSubtotals?.locations.get(space.space_id);
                            return {
                                ...rsrvItem,
                                ...space,
                                type: "Location",
                                name: strVar(space.space.space_name),
                                id: space.space_id,
                                instructions: strVar(space.space_instructions),
                                resourceOccurrenceTotal: "",
                                resourceOccurrenceListPrice: "",
                                locationOccurrenceTotal: dollar.format(pricing?.total || 0),
                                locationOccurrenceListPrice: dollar.format(pricing?.list || 0),
                            };
                        }),
                    );

                    jSith.forEach(rsrvItem.spaceReservation, function (key, spRsrvItem) {
                        occurrencesObject.push({
                            occ: rsrvItem,
                            object: spRsrvItem,
                            objectType: 4,
                        });
                    });

                    jSith.forEach(rsrvItem.resourceReservation, function (key, rsRsrvItem) {
                        occurrencesObject.push({
                            occ: rsrvItem,
                            object: rsRsrvItem,
                            objectType: 6,
                        });
                    });

                    //pref occurrences
                    let prefRsrv = S25Util.propertyGetParentWithChildValue(
                        prefJson,
                        "reservation_id",
                        rsrv.reservation_id,
                    );
                    if (prefRsrv) {
                        prefOccurrences.push(rsrvItem);
                        if (prefRsrv.resource_reservation)
                            prefOccurrencesResources.push(
                                ...prefRsrv.resource_reservation.map((resource: any) => {
                                    const pricing = occSubtotals?.resources.get(resource.resource_id);
                                    return {
                                        ...rsrvItem,
                                        type: "Resource",
                                        name: strVar(resource.resource_name),
                                        quantity: resource.quantity,
                                        id: resource.resource_id,
                                        instructions: strVar(resource.resource_instructions),
                                        resourceOccurrenceTotal: dollar.format(pricing?.total || 0),
                                        resourceOccurrenceListPrice: dollar.format(pricing?.list || 0),
                                        locationOccurrenceTotal: "",
                                        locationOccurrenceListPrice: "",
                                    };
                                }),
                            );
                        if (prefRsrv.space_reservation)
                            prefOccurrencesLocations.push(
                                ...prefRsrv.space_reservation.map((space: any) => {
                                    const pricing = occSubtotals?.locations.get(space.space_id);
                                    return {
                                        ...rsrvItem,
                                        type: "Location",
                                        name: strVar(space.space_name),
                                        attendance: space.attendance,
                                        id: space.space_id,
                                        instructions: strVar(space.space_instructions),
                                        resourceOccurrenceTotal: "",
                                        resourceOccurrenceListPrice: "",
                                        locationOccurrenceTotal: dollar.format(pricing?.total || 0),
                                        locationOccurrenceListPrice: dollar.format(pricing?.list || 0),
                                    };
                                }),
                            );

                        jSith.forEach(prefRsrv.space_reservation, function (key, spRsrvItem) {
                            prefOccurrencesObject.push({
                                occ: rsrvItem,
                                object: spRsrvItem,
                                objectType: 4,
                            });
                        });

                        jSith.forEach(prefRsrv.resource_reservation, function (key, rsRsrvItem) {
                            prefOccurrencesObject.push({
                                occ: rsrvItem,
                                object: rsRsrvItem,
                                objectType: 6,
                            });
                        });

                        BpeUtil.processSpRsRsrv(
                            prefRsrv,
                            prefLocationHash,
                            prefLocations,
                            prefResourceHash,
                            prefResources,
                        );
                    }

                    //occurrences
                    BpeUtil.processSpRsRsrv(rsrv, locationHash, locations, resourceHash, resources, profile);
                });

                additionalTime = {
                    setup: S25Util.parseXmlDuration(profile.setup_profile?.setup_tm || "P"), // P => 0 ms
                    takeDown: S25Util.parseXmlDuration(profile.takedown_profile?.tdown_tm || "P"),
                    preEvent: S25Util.parseXmlDuration(profile.pre_event || "P"),
                    postEvent: S25Util.parseXmlDuration(profile.post_event || "P"),
                };

                locations.sort(S25Util.shallowSort("itemName"));
                resources.sort(S25Util.shallowSort("itemName"));
                prefLocations.sort(S25Util.shallowSort("itemName"));
                prefResources.sort(S25Util.shallowSort("itemName"));

                globalOccurrences = S25Util.array.uniqueDeep(globalOccurrences.concat(occurrences));
                globalLocations = S25Util.array.uniqueDeep(globalLocations.concat(locations));
                globalResources = S25Util.array.uniqueDeep(globalResources.concat(resources));

                globalPrefOccurrences = S25Util.array.uniqueDeep(globalPrefOccurrences.concat(prefOccurrences));
                globalPrefLocations = S25Util.array.uniqueDeep(globalPrefLocations.concat(prefLocations));
                globalPrefResources = S25Util.array.uniqueDeep(globalPrefResources.concat(prefResources));

                let objectData: any = {
                    occurrences: occurrences,
                    occurrencesObject: occurrencesObject,
                    locations: locations,
                    resources: resources,
                    prefOccurrences: prefOccurrences,
                    prefOccurrencesObject: prefOccurrencesObject,
                    prefLocations: prefLocations,
                    prefResources: prefResources,
                };

                jSith.forEach([false, true], function (key, isCSV) {
                    jSith.forEach([false, true], function (key, isPref) {
                        jSith.forEach(
                            [
                                "occurrencesString",
                                "occurrencesWithLocationsString",
                                "occurrencesWithResourcesString",
                                "occurrencesWithLocationsAndResourcesString",
                                "locationsString",
                                "resourcesString",
                            ],
                            function (key, type) {
                                if (
                                    isCSV &&
                                    ["occurrencesString", "locationsString", "resourcesString"].indexOf(type) === -1
                                ) {
                                    return;
                                }

                                let prefAwareType = isPref
                                    ? "pref" + type.substring(0, 1).toUpperCase() + type.substring(1, type.length)
                                    : type;
                                let finalType = isCSV ? prefAwareType + "CSV" : prefAwareType;
                                let mapping = typeMap[prefAwareType] || typeMap[type];
                                let dataType = mapping.dataTypeBase;
                                dataType = isPref
                                    ? "pref" +
                                      dataType.substring(0, 1).toUpperCase() +
                                      dataType.substring(1, dataType.length)
                                    : dataType;
                                stringData[finalType] = (isCSV ? buildCSV : buildTable)(
                                    mapping.headers,
                                    objectData[dataType],
                                    mapping.rowF,
                                    null,
                                    source as any,
                                );
                                defaultStringData[finalType] = "";
                            },
                        );
                    });
                });

                if (!S25Util.isEmptyBean(stringData)) {
                    stringDataArr.push({ profileName: profile.profile_name, stringData: stringData });
                }
            });

            let eventId = parseInt(S25Util.propertyGetVal(eventData, "event_id"));
            let stringData: any = {},
                expectedCount = "",
                registeredCount = "",
                segmentComment = "";

            stringDataArr = stringDataArr.length ? stringDataArr : [{ stringData: defaultStringData }];
            jSith.forEach(stringDataArr, function (key, sd) {
                if (stringDataArr.length > 1) {
                    jSith.forEach(sd.stringData, function (finalType, s) {
                        stringData[finalType] = stringData[finalType] || "";
                        if (stringData[finalType]) {
                            stringData[finalType] += "<br/>";
                        }
                        stringData[finalType] +=
                            "<div style='font-weight:bold;'>Segment " +
                            strVar(sd.profileName) +
                            ":&nbsp;</div><br/>" +
                            s;
                    });
                } else {
                    stringData = sd.stringData;
                }
            });

            expectedCountArr = expectedCountArr.length ? expectedCountArr : [{ value: "" }];
            jSith.forEach(expectedCountArr, function (key, e) {
                if (expectedCountArr.length > 1) {
                    if (expectedCount) {
                        expectedCount += "<br/>";
                    }
                    expectedCount += strVar(e.profileName) + ": " + e.value;
                } else {
                    expectedCount = e.value;
                }
            });

            registeredCountArr = registeredCountArr.length ? registeredCountArr : [{ value: "" }];
            jSith.forEach(registeredCountArr, function (key, e) {
                if (registeredCountArr.length > 1) {
                    if (registeredCount) {
                        registeredCount += "<br/>";
                    }
                    registeredCount += "Segment " + strVar(e.profileName) + ": " + e.value;
                } else {
                    registeredCount = e.value;
                }
            });

            commentArr = commentArr.length ? commentArr : [{ value: "" }];
            jSith.forEach(commentArr, function (key, e) {
                if (commentArr.length > 1) {
                    if (segmentComment) {
                        segmentComment += "<br/>";
                    }
                    segmentComment += "Segment " + strVar(e.profileName) + ": " + e.value;
                } else {
                    segmentComment = e.value;
                }
            });

            startDate = S25Util.coalesce(
                startDate,
                S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "start_date")),
            );
            endDate = S25Util.coalesce(
                endDate,
                S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "end_date")),
            );
            //actual event start and end times from profile init start and end dates
            eventStartDate = S25Util.coalesce(
                eventStartDate,
                S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "start_date")),
            );
            eventEndDate = S25Util.coalesce(
                eventEndDate,
                S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "end_date")),
            );
            preEventDate = S25Util.coalesce(
                preEventDate,
                S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "start_date")),
            );
            postEventDate = S25Util.coalesce(
                postEventDate,
                S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "end_date")),
            );

            let ret: any = {
                timeFormat: resp.timeFormat,
                dateFormat: resp.dateFormat,
                dateTimeFormat: resp.dateTimeFormat,
                source: source,
                eventId: eventId,
                eventUrl: S25Util.getEventDetailsUrl(eventId),
                eventLink: '<a href="' + S25Util.getEventDetailsUrl(eventId) + '" target="_blank">Event Details</a>',
                homeUrl: S25Const.baseUrl,
                eventLocator: S25Util.propertyGetVal(eventData, "event_locator"),
                eventName: strVar(S25Util.propertyGetVal(eventData, "event_name")),
                eventTitle: strVar(S25Util.propertyGetVal(eventData, "event_title")),
                versionNumber: parseInt(S25Util.propertyGetVal(eventData, "version_number")) || 0,
                actionType:
                    (parseInt(S25Util.propertyGetVal(eventData, "version_number")) || 0) === 0 ? "create" : "edit",
                eventTypeId: parseInt(S25Util.propertyGetVal(eventData, "event_type_id")),
                eventTypeName: strVar(S25Util.propertyGetVal(eventData, "event_type_name")),
                description: S25Util.toStr(S25Util.propertyGetVal(descriptionNode, "text")).replace(/\n/g, ""), //this is rich text
                internalNote: strVar(S25Util.propertyGetVal(internalNoteNode, "text")).replace(/\n/g, "<br/>"),
                confirmationNote: strVar(S25Util.propertyGetVal(confirmationNoteNode, "text")).replace(/\n/g, "<br/>"),
                comment: strVar(segmentComment).replace(/\n/g, "<br/>"),
                customAttributes: customAttributes,
                customAttributesList,
                categories,
                billItems: billItemList,
                relationships,
                tasks,
                contacts,
                segments,
                organizationsList,
                roleContactId: contId,
                roleContactName: contName,
                roleContactEmail: contEmail,
                roleContactAddr: contAddress,
                roleContactFirst: contFirst,
                roleContactLast: contLast,
                roleContactMiddle: contMiddle,
                roleContactPhone: contPhone,
                roleContactFax: contFax,
                expectedCount: expectedCount,
                expectedHeadcounts: expectedCountArr.map((obj) => Number(obj.value)),
                registeredCount: registeredCount,
                state: parseInt(S25Util.propertyGetVal(eventData, "state")),
                stateName: strVar(S25Util.propertyGetVal(eventData, "state_name")),
                requesterEmail: strVar(S25Util.propertyGetVal(requester, "email")),
                requestorEmail: strVar(S25Util.propertyGetVal(requester, "email")), //alternate spelling
                schedulerEmail: strVar(S25Util.propertyGetVal(scheduler, "email")),
                primaryOrganization: primaryOrganization,
                requirements: requirements,
                organizations: orgVars,
                dates: {
                    lastModDate: S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "last_mod_dt")),
                    creationDate: S25Util.date.parseDropTZ(S25Util.propertyGetVal(eventData, "creation_dt")),
                    currentLocalDate: new Date(),
                    //reservation start and end dates from earliest/ latest reservations
                    startDate,
                    endDate,
                    eventStartDate,
                    eventEndDate,
                    eventSetupDate: startDate,
                    eventTakedownDate: endDate,
                    preEventDate,
                    postEventDate,
                },
                pricing: {
                    grandTotal: grandTotal,
                },
                durations: {
                    setup: S25Util.getDurationString(additionalTime.setup / 60_000),
                    takeDown: S25Util.getDurationString(additionalTime.takeDown / 60_000),
                    preEvent: S25Util.getDurationString(additionalTime.preEvent / 60_000),
                    postEvent: S25Util.getDurationString(additionalTime.postEvent / 60_000),
                    event: S25Util.getDurationString((eventEndDate - eventStartDate) / 60_000),
                    preToPost: S25Util.getDurationString((postEventDate - preEventDate) / 60_000),
                    setupToTakedown: S25Util.getDurationString((endDate - startDate) / 60_000),
                },

                //occurrence info:
                occurrences: globalOccurrences,
                occurrencesResources,
                occurrencesLocations,
                occurrencesLocationsResources: occurrencesLocations
                    .concat(occurrencesResources)
                    .sort((a, b) => a.dates.rsrvStartDate - b.dates.rsrvStartDate),
                locations: globalLocations,
                resources: globalResources,

                // We define pre-items here to make sure that they are always defined, even if there is no pre-data
                preLocations: [],
                preResources: [],
                prePrefLocations: [],
                prePrefResources: [],
                preRequirements: [],

                //pref occurrence info:
                prefOccurrences: globalPrefOccurrences,
                prefOccurrencesResources,
                prefOccurrencesLocations,
                prefOccurrencesLocationsResources: prefOccurrencesLocations
                    .concat(prefOccurrencesResources)
                    .sort((a, b) => a.dates.rsrvStartDate - b.dates.rsrvStartDate),
                prefLocations: globalPrefLocations,
                prefResources: globalPrefResources,

                //todo info:
                todo: todo,
                customAttributeData: eventData.custom_attribute,
            };
            ret.testTaskTriggers = BpeUtil.evaluateTaskTriggers.bind(this, ret);
            ret.testAttributes = BpeUtil.evaluateCustomAttributes;
            ret.testHeadcount = BpeUtil.evaluateHeadcount;
            S25Util.extend(ret, stringData);
            generateDateStrings(ret, resp.timeFormat, resp.dateFormat, resp.dateTimeFormat);

            ret.currentGroupId = resp.currentGroupId;
            ret.currentContactName = resp.currentContactName;

            let prePromise = jSith.when();
            if (preEventData) {
                prePromise = BpeUtil.getVariables(preEventData, profileId, null);
            }

            return prePromise.then(function (preRet: any) {
                if (preRet) {
                    jSith.forEach(preRet, function (key, value) {
                        ret["pre" + key.charAt(0).toUpperCase() + key.substring(1)] = value;
                    });
                    delete ret.prePreLocations;
                    delete ret.prePreResources;
                    delete ret.prePrePrefLocations;
                    delete ret.prePrePrefResources;
                    delete ret.prePreRequirements;
                }
                return ret;
            });
        });
    }

    /* no event tasks, no need to trigger email
           no preTask, no need to trigger email, ie: in event form just edit event title
           task still in progress, no trigger email
           compare the pre-event data to the post save data. If the pre-event data had in progress tasks but the post save data has all tasks are complete, trigger email
     */
    public static getTaskTrigger(type: string, taskData: any, preTaskData?: any) {
        let data = [];
        let preTask = [];
        let noTask = [];

        switch (type) {
            case "ap":
                noTask = taskData.filter((i: any) => i.type === "Assign");
                preTask = preTaskData.filter((i: any) => i.type === "Assign" && i.state === "In Progress");
                data = taskData.filter((i: any) => i.type === "Assign" && i.state === "In Progress");
                break;
            case "fyi":
                noTask = taskData.filter((i: any) => i.type === "FYI");
                preTask = preTaskData.filter((i: any) => i.type === "FYI" && i.state === "In Progress");
                data = taskData.filter((i: any) => i.type === "FYI" && i.state === "In Progress");
                break;
            case "np":
                noTask = taskData.filter((i: any) => i.type !== "Assign" && i.type !== "FYI");
                preTask = preTaskData.filter(
                    (i: any) => i.type !== "FYI" && i.type !== "Assign" && i.state === "In Progress",
                );
                data = taskData.filter(
                    (i: any) =>
                        i.type !== "Assign" && i.type !== "FYI" && i.type !== "Assign" && i.state === "In Progress",
                );
                break;
        }

        let finalRetVal: boolean = true;
        if (noTask.length === 0) {
            // finalRetVal = false;  // ANG-4777
        } else if (preTaskData && preTaskData.length === 0) {
            finalRetVal = false;
        } else if (data.length > 0) {
            finalRetVal = false;
        } else if (data.length === 0 && preTask.length === 0) {
            // finalRetVal = false; // ANG-4777
        } else {
            // do nothing
        }

        return finalRetVal;
    }

    public static getAutoCompletedTaskTrigger(type: string, taskData: any) {
        let data = [];
        switch (type) {
            case "ap":
                data = taskData.filter((i: any) => i.type === "Assign" && i.state === "In Progress");
                break;
            case "fyi":
                data = taskData.filter((i: any) => i.type === "FYI" && i.state === "In Progress");
                break;
            case "np":
                data = taskData.filter(
                    (i: any) => i.type !== "Assign" && i.type !== "FYI" && i.state === "In Progress",
                );
                break;
        }

        let returnVal: boolean = true;
        if (data.length > 0) returnVal = false;
        return returnVal;
    }

    public static scenarioGetData(scenario: EmailScenario): EmailScenario {
        // ANG-3553 Switches scenarios to always store data in code mode
        switch (scenario.mode) {
            case "form":
                return BpeUtil.legacyScenarioFormToHybrid(scenario);
            case "code":
                return BpeUtil.legacyScenarioCodeToHybrid(scenario);
            case "hybridForm":
            case "hybridCode":
                return {
                    ...scenario,
                    codeData: BpeUtil.parseCode(scenario.code),
                };
        }
    }

    public static legacyScenarioFormToHybrid(scenario: EmailScenario): EmailScenario {
        return {
            ...scenario,
            mode: "hybridForm",
            code: "",
            codeData: {
                on: scenario.onAction,
                sources: {
                    task: !!scenario.onTaskAction,
                    "event-form": !!scenario.onEventFormAction,
                    express: !!scenario.onExpressAction,
                    "event-state-change": !!scenario.onEventStateChangeAction,
                    cancelTodos: !!scenario.onTodoAction,
                },
                taskTriggers: {
                    assignments: !!scenario.allApDone,
                    approvals: !!scenario.allNpDone,
                    fyis: !!scenario.allFyiDone,
                },
                pre: {
                    states: { include: scenario.preEventStates, exclude: [] },
                    types: { include: [], exclude: [] },
                    locations: { include: [], exclude: [] },
                    resources: { include: [], exclude: [] },
                    primaryOrgs: { include: [], exclude: [] },
                    requirements: { include: [], exclude: [] },
                    securityGroups: { include: [], exclude: [] },
                    customAttributes: { include: [], exclude: [] },
                    expectedHeadcount: { operator: "none" },
                },
                post: {
                    usePrefLocations: !!scenario.inclLocPref,
                    usePrefResources: !!scenario.inclResPref,
                    states: { include: scenario.eventStates, exclude: [] },
                    types: { include: scenario.eventTypes, exclude: [] },
                    locations: { include: scenario.locations, exclude: [] },
                    resources: { include: scenario.resources, exclude: [] },
                    primaryOrgs: { include: scenario.organizations, exclude: [] },
                    requirements: { include: scenario.requirements, exclude: [] },
                    securityGroups: { include: scenario.securityGroups, exclude: [] },
                    customAttributes: { include: scenario.attributes, exclude: [] },
                    expectedHeadcount: { operator: "none" },
                },
            },
        };
    }

    public static legacyScenarioCodeToHybrid(scenario: EmailScenario): EmailScenario {
        return {
            ...scenario,
            mode: "hybridCode",
            codeData: {
                on: scenario.onAction,
                sources: {
                    task: !!scenario.onTaskAction,
                    "event-form": !!scenario.onEventFormAction,
                    express: !!scenario.onExpressAction,
                    "event-state-change": !!scenario.onEventStateChangeAction,
                    cancelTodos: !!scenario.onTodoAction,
                },
                taskTriggers: {
                    assignments: false,
                    approvals: false,
                    fyis: false,
                },
                pre: {
                    states: { include: [], exclude: [] },
                    types: { include: [], exclude: [] },
                    locations: { include: [], exclude: [] },
                    resources: { include: [], exclude: [] },
                    primaryOrgs: { include: [], exclude: [] },
                    requirements: { include: [], exclude: [] },
                    securityGroups: { include: [], exclude: [] },
                    customAttributes: { include: [], exclude: [] },
                    expectedHeadcount: { operator: "none" },
                },
                post: {
                    states: { include: [], exclude: [] },
                    types: { include: [], exclude: [] },
                    locations: { include: [], exclude: [] },
                    resources: { include: [], exclude: [] },
                    primaryOrgs: { include: [], exclude: [] },
                    requirements: { include: [], exclude: [] },
                    securityGroups: { include: [], exclude: [] },
                    customAttributes: { include: [], exclude: [] },
                    expectedHeadcount: { operator: "none" },
                },
            },
        };
    }

    public static evaluateTaskTriggers(vars: any, triggers: string[]) {
        const ap = !!triggers.find((item) => /assign/i.test(item));
        const np = !!triggers.find((item) => /appr/i.test(item));
        const fyi = !!triggers.find((item) => /fyi/i.test(item));
        if (!ap && !np && !fyi) return true; // None included, test is valid

        //check any task trigger done
        let getFinalTasks: any[] = vars.tasks.filter((i: any) => i.type !== "Public"); // excluded Public = todo, a user with 8.0 View Task List,not see workflow from WS
        let getFinalPreTasks: any[] = []; // excluded Public = todo, a user with 8.0 View Task List,not see workflow from WS
        let allApDone: boolean = true;
        let allNpDone: boolean = true;
        let allFyiDone: boolean = true;

        if (vars.preTasks) getFinalPreTasks = vars.preTasks.filter((i: any) => i.type !== "Public");
        const hasTasks = !!getFinalTasks.length;
        const hadTasks = !!getFinalPreTasks.length;
        const eventTypeChanged = vars.preEventTypeId && vars.preEventTypeId !== vars.eventTypeId;
        const newEvent = vars.versionNumber === 0;

        if (!hasTasks) return false; // no need to send an email if no tasks and taskTrigger
        if (hasTasks && eventTypeChanged) return false; // change event type will not have complete task email

        if (newEvent) {
            if (hasTasks) {
                //ANG-4854 auto completed tasks need to trigger
                if (ap) allApDone = BpeUtil.getAutoCompletedTaskTrigger("ap", getFinalTasks);
                if (np) allNpDone = BpeUtil.getAutoCompletedTaskTrigger("np", getFinalTasks);
                if (fyi) allFyiDone = BpeUtil.getAutoCompletedTaskTrigger("fyi", getFinalTasks);
            } else {
                return false; // no tasks, no task email trigger when create new event,
            }
        }

        if (hasTasks && hadTasks) {
            if (ap) allApDone = BpeUtil.getTaskTrigger("ap", getFinalTasks, getFinalPreTasks);
            if (np) allNpDone = BpeUtil.getTaskTrigger("np", getFinalTasks, getFinalPreTasks);
            if (fyi) allFyiDone = BpeUtil.getTaskTrigger("fyi", getFinalTasks, getFinalPreTasks);
        } else if (
            hasTasks &&
            !hadTasks &&
            vars.preEventTypeId &&
            vars.preEventTypeId === vars.eventTypeId &&
            !newEvent
        ) {
            return false; // change event type will  not have  complete task email
        }

        return allApDone && allNpDone && allFyiDone;
    }

    public static evaluateCustomAttributes(
        scenarioAttributes: { id: number; name: string; operator: string; value: string | number }[],
        eventAttributes: {
            attribute_id: number;
            attribute_type: Rules.AttributeType;
            attribute_value: string | number;
            multi_val?: NumericalBoolean;
        }[],
    ) {
        if (!scenarioAttributes?.length) return true; // No requirements = pass
        if (!eventAttributes?.length) return false; // No data = fail

        const eventAttributeMap = S25Util.fromEntries(
            eventAttributes.map((attribute) => [attribute.attribute_id, attribute]),
        );

        for (let attribute of scenarioAttributes) {
            const eventAttribute = eventAttributeMap[attribute.id];
            if (!eventAttribute) continue;
            const match = BpeUtil.evaluateCustomAttribute(
                eventAttribute.attribute_type,
                attribute.operator,
                attribute.value,
                eventAttribute.attribute_value,
                !!eventAttribute.multi_val,
            );
            if (match) return true; // Short circuit because we are looking for *at least * one match
        }

        return false;
    }

    public static evaluateCustomAttribute(
        type: Rules.AttributeType,
        operator: string,
        value: any,
        actual: any,
        isMulti: boolean,
    ) {
        operator = operator.replace(/^=$/, "==");
        switch (type) {
            case 2:
            case 4:
            case 6:
                return value == actual;
            case "D":
                const actualDate = S25Util.date.toS25ISODateStr(actual);
                const date = S25Util.date.toS25ISODateStr(value);
                return eval(`'${actualDate}' ${operator} '${date}'`);
            case "T":
                const actualTime = BpeUtil.util.toTimeString(actual);
                const time = BpeUtil.util.toTimeString(value);
                return eval(`'${actualTime}' ${operator} '${time}'`);
            case "E":
                const actualDateTime = S25Util.date.toS25ISODateTimeStr(actual, false);
                const dateTime = S25Util.date.toS25ISODateTimeStr(value, false);
                return eval(`'${actualDateTime}' ${operator} '${dateTime}'`);
            case "F":
            case "N":
                return eval(`${actual} ${operator} ${value}`);
            case "S":
            case "X":
            case "R":
                value = value.toLowerCase();
                let stringValues = [actual];
                if (isMulti) {
                    const [data, err] = S25Util.parseJson<{ item: string }[]>(actual as string);
                    if (!data || err) return false;
                    stringValues = S25Util.array.forceArray(data).map(({ item }) => item);
                }
                if (operator === "==") {
                    return stringValues.some((actual) => actual.toLowerCase() === value);
                } else if (operator === "contains") {
                    return stringValues.some((actual) => actual.toLowerCase().includes(value));
                }
                return false;
            case "B":
                return (actual === "T") === value;
        }
    }

    public static evaluateHeadcount(
        headcounts: number[],
        options: { operator: string; value?: number; value2?: number },
    ) {
        const { operator, value, value2 } = Object.assign({ operator: "none", value: 0, value2: 0 }, options || {});
        if (operator === "none") return true;
        if (!headcounts?.length) return false;

        return headcounts.some((headcount) => {
            if (operator === "between") return headcount >= value && headcount <= value2;
            if (operator === "=") return headcount === value;
            return eval(`${headcount} ${operator} ${value}`);
        });
    }
}
